
import headerTab from '~/components/common/header'
import footerTab from '~/components/common/footer'
import cookies from '@/utils/cookies'
import { setStore, removeStore } from '@/utils/storage'
import { downloadlibrary } from '~/api/course.js'
import { gaodeMap } from '~/utils/amap'
import { mapGetters } from 'vuex'

export default {
  name: 'Default',
  components: {
    headerTab,
    footerTab
  },
  props: {},
  data() {
    return {}
  },
  head() {
    return {
      title: this.$store.state.clientData.name || '',
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: this.$store.state.webInfo
            ? this.$store.state.webInfo.websiteIco
            : ''
        },
        { rel: 'stylesheet', type: 'text/css', href: '/wangEditor.min.css' }
      ],
      script: [
        { src: '//lib.baomitu.com/jquery/3.5.1/jquery.min.js' },
        { src: '//live-cdn.baijiayun.com/bplayer/latest/bplayer.js' },
        { src: '//live-cdn.baijiayun.com/bplayer/latest/dep/ffplayer.js' },
        { src: '//player.polyv.net/script/player.js' },
        { src: '//player.polyv.net/script/polyvplayer.min.js' }
      ]
    }
  },
  computed: {
    ...mapGetters(['webInfo'])
  },
  watch: {
    '$store.state.lang'(val) {
      this.$i18n.locale = val
    }
  },
  created() { },
  mounted() {
    this.$i18n.locale = cookies.getInClient('lang') || 'zh'
    document.addEventListener('click', (e) => {
      // console.log(e.target.getAttribute('data-id'), 111)
      if (e && e.target) {
        if (e && e.target) {
          if (
            e.target.parentNode &&
            e.target.parentNode.className &&
            e.target.parentNode.className.indexOf('file_a') > -1 &&
            e.target.parentNode.getAttribute('data-id')
          ) {
            this.downloadlibrary(e.target.parentNode.getAttribute('data-id'))
            return
          }
          if (
            e.target &&
            e.target.className &&
            e.target.className.indexOf('file_a') > -1 &&
            e.target.getAttribute('data-id')
          ) {
            this.downloadlibrary(e.target.getAttribute('data-id'))
            return
          }
        }
      }
    })

    if (this.webInfo.amapKeyId && this.webInfo.amapKeySecret) {
      gaodeMap(this.webInfo.amapKeyId, this.webInfo.amapKeySecret)
    }
  },
  methods: {
    downloadlibrary(id) {
      downloadlibrary(id).then((res) => {
        if (res) {
          setStore('is_download_file', true)
          window.open(res, '_blnak')
          setTimeout(() => {
            removeStore('is_download_file')
          }, 2000)
        }
      })
    }
  }
}
