
import { infoFooter } from '@/api/home'
export default {
  data() {
    return {
      componentInfo: {}
    }
  },
  mounted() {
    this.getFooterInfo()
  },
  methods: {
    getFooterInfo() {
      infoFooter().then(res => {
        this.componentInfo = res
      })
    },
    getLinkUrl(url) {
      const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
      return urlregex.test(url) ? url : ''
    }
  }
}
