import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _13313304 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _46e9b7d0 = () => interopDefault(import('../pages/agreement.vue' /* webpackChunkName: "pages/agreement" */))
const _e561fc04 = () => interopDefault(import('../pages/appCompany.vue' /* webpackChunkName: "pages/appCompany" */))
const _48feb668 = () => interopDefault(import('../pages/certificate/index.vue' /* webpackChunkName: "pages/certificate/index" */))
const _0934e88c = () => interopDefault(import('../pages/community/index.vue' /* webpackChunkName: "pages/community/index" */))
const _5667428b = () => interopDefault(import('../pages/company.vue' /* webpackChunkName: "pages/company" */))
const _74c5d50c = () => interopDefault(import('../pages/development/index.vue' /* webpackChunkName: "pages/development/index" */))
const _d5e5fc40 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _81343562 = () => interopDefault(import('../pages/forget.vue' /* webpackChunkName: "pages/forget" */))
const _518028f8 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _4d37ce0f = () => interopDefault(import('../pages/knowledge/index.vue' /* webpackChunkName: "pages/knowledge/index" */))
const _6956de19 = () => interopDefault(import('../pages/lecturer/index.vue' /* webpackChunkName: "pages/lecturer/index" */))
const _5eb4a4f7 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _b333599e = () => interopDefault(import('../pages/permission.vue' /* webpackChunkName: "pages/permission" */))
const _83cb86f6 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _7032e970 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _6cf780fe = () => interopDefault(import('../pages/tpl.vue' /* webpackChunkName: "pages/tpl" */))
const _17072420 = () => interopDefault(import('../pages/watch/index.vue' /* webpackChunkName: "pages/watch/index" */))
const _6333dde1 = () => interopDefault(import('../pages/development/estimate/index.vue' /* webpackChunkName: "pages/development/estimate/index" */))
const _2e06d528 = () => interopDefault(import('../pages/development/qualification/index.vue' /* webpackChunkName: "pages/development/qualification/index" */))
const _5c94bec6 = () => interopDefault(import('../pages/development/study/index.vue' /* webpackChunkName: "pages/development/study/index" */))
const _b7d6972c = () => interopDefault(import('../pages/home/rank.vue' /* webpackChunkName: "pages/home/rank" */))
const _098c75b6 = () => interopDefault(import('../pages/integral/mall.vue' /* webpackChunkName: "pages/integral/mall" */))
const _5f04d092 = () => interopDefault(import('../pages/investigate/view.vue' /* webpackChunkName: "pages/investigate/view" */))
const _50f03ec3 = () => interopDefault(import('../pages/watch/catePanel.vue' /* webpackChunkName: "pages/watch/catePanel" */))
const _346c423d = () => interopDefault(import('../pages/watch/course.vue' /* webpackChunkName: "pages/watch/course" */))
const _87978e46 = () => interopDefault(import('../pages/watch/practice.vue' /* webpackChunkName: "pages/watch/practice" */))
const _6e7ee5b2 = () => interopDefault(import('../pages/watch/task.vue' /* webpackChunkName: "pages/watch/task" */))
const _b2698126 = () => interopDefault(import('../pages/account/exam/detail.vue' /* webpackChunkName: "pages/account/exam/detail" */))
const _8b58d97e = () => interopDefault(import('../pages/account/exam/examQuestion.vue' /* webpackChunkName: "pages/account/exam/examQuestion" */))
const _789dacf8 = () => interopDefault(import('../pages/account/exam/examSuccess.vue' /* webpackChunkName: "pages/account/exam/examSuccess" */))
const _105d936d = () => interopDefault(import('../pages/account/exam/record.vue' /* webpackChunkName: "pages/account/exam/record" */))
const _16f5fbba = () => interopDefault(import('../pages/account/plan/detail.vue' /* webpackChunkName: "pages/account/plan/detail" */))
const _8f6f7736 = () => interopDefault(import('../pages/development/estimate/batchEvaluation.vue' /* webpackChunkName: "pages/development/estimate/batchEvaluation" */))
const _b648da86 = () => interopDefault(import('../pages/development/estimate/evaluation.vue' /* webpackChunkName: "pages/development/estimate/evaluation" */))
const _4c7e0135 = () => interopDefault(import('../pages/development/estimate/report.vue' /* webpackChunkName: "pages/development/estimate/report" */))
const _7156d169 = () => interopDefault(import('../pages/account/plan/task/add.vue' /* webpackChunkName: "pages/account/plan/task/add" */))
const _6f30c48e = () => interopDefault(import('../pages/account/plan/task/detail.vue' /* webpackChunkName: "pages/account/plan/task/detail" */))
const _339f5818 = () => interopDefault(import('../pages/knowledge/offcourse/components/AssessAttachment.vue' /* webpackChunkName: "pages/knowledge/offcourse/components/AssessAttachment" */))
const _2be570ce = () => interopDefault(import('../pages/account/exam/_id.vue' /* webpackChunkName: "pages/account/exam/_id" */))
const _43632834 = () => interopDefault(import('../pages/account/task/_id.vue' /* webpackChunkName: "pages/account/task/_id" */))
const _22e4c2c0 = () => interopDefault(import('../pages/integral/goods/_id.vue' /* webpackChunkName: "pages/integral/goods/_id" */))
const _76e5d956 = () => interopDefault(import('../pages/investigate/analyze/_id.vue' /* webpackChunkName: "pages/investigate/analyze/_id" */))
const _793e5cd9 = () => interopDefault(import('../pages/knowledge/course/_id.vue' /* webpackChunkName: "pages/knowledge/course/_id" */))
const _c1f8819c = () => interopDefault(import('../pages/knowledge/offcourse/_id.vue' /* webpackChunkName: "pages/knowledge/offcourse/_id" */))
const _7932b241 = () => interopDefault(import('../pages/knowledge/project/_id.vue' /* webpackChunkName: "pages/knowledge/project/_id" */))
const _8ba6a84e = () => interopDefault(import('../pages/lecturer/view/_id.vue' /* webpackChunkName: "pages/lecturer/view/_id" */))
const _1490c63e = () => interopDefault(import('../pages/live/watch/_watch.vue' /* webpackChunkName: "pages/live/watch/_watch" */))
const _3db3271a = () => interopDefault(import('../pages/redirect/_path.vue' /* webpackChunkName: "pages/redirect/_path" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _13313304,
    name: "account___zh"
  }, {
    path: "/agreement",
    component: _46e9b7d0,
    name: "agreement___zh"
  }, {
    path: "/appCompany",
    component: _e561fc04,
    name: "appCompany___zh"
  }, {
    path: "/certificate",
    component: _48feb668,
    name: "certificate___zh"
  }, {
    path: "/community",
    component: _0934e88c,
    name: "community___zh"
  }, {
    path: "/company",
    component: _5667428b,
    name: "company___zh"
  }, {
    path: "/development",
    component: _74c5d50c,
    name: "development___zh"
  }, {
    path: "/en",
    component: _d5e5fc40,
    name: "index___en"
  }, {
    path: "/forget",
    component: _81343562,
    name: "forget___zh"
  }, {
    path: "/home",
    component: _518028f8,
    name: "home___zh"
  }, {
    path: "/knowledge",
    component: _4d37ce0f,
    name: "knowledge___zh"
  }, {
    path: "/lecturer",
    component: _6956de19,
    name: "lecturer___zh"
  }, {
    path: "/login",
    component: _5eb4a4f7,
    name: "login___zh"
  }, {
    path: "/permission",
    component: _b333599e,
    name: "permission___zh"
  }, {
    path: "/register",
    component: _83cb86f6,
    children: [{
      path: "",
      component: _7032e970,
      name: "register___zh"
    }]
  }, {
    path: "/tpl",
    component: _6cf780fe,
    name: "tpl___zh"
  }, {
    path: "/watch",
    component: _17072420,
    name: "watch___zh"
  }, {
    path: "/development/estimate",
    component: _6333dde1,
    name: "development-estimate___zh"
  }, {
    path: "/development/qualification",
    component: _2e06d528,
    name: "development-qualification___zh"
  }, {
    path: "/development/study",
    component: _5c94bec6,
    name: "development-study___zh"
  }, {
    path: "/en/account",
    component: _13313304,
    name: "account___en"
  }, {
    path: "/en/agreement",
    component: _46e9b7d0,
    name: "agreement___en"
  }, {
    path: "/en/appCompany",
    component: _e561fc04,
    name: "appCompany___en"
  }, {
    path: "/en/certificate",
    component: _48feb668,
    name: "certificate___en"
  }, {
    path: "/en/community",
    component: _0934e88c,
    name: "community___en"
  }, {
    path: "/en/company",
    component: _5667428b,
    name: "company___en"
  }, {
    path: "/en/development",
    component: _74c5d50c,
    name: "development___en"
  }, {
    path: "/en/forget",
    component: _81343562,
    name: "forget___en"
  }, {
    path: "/en/home",
    component: _518028f8,
    name: "home___en"
  }, {
    path: "/en/knowledge",
    component: _4d37ce0f,
    name: "knowledge___en"
  }, {
    path: "/en/lecturer",
    component: _6956de19,
    name: "lecturer___en"
  }, {
    path: "/en/login",
    component: _5eb4a4f7,
    name: "login___en"
  }, {
    path: "/en/permission",
    component: _b333599e,
    name: "permission___en"
  }, {
    path: "/en/register",
    component: _83cb86f6,
    children: [{
      path: "",
      component: _7032e970,
      name: "register___en"
    }]
  }, {
    path: "/en/tpl",
    component: _6cf780fe,
    name: "tpl___en"
  }, {
    path: "/en/watch",
    component: _17072420,
    name: "watch___en"
  }, {
    path: "/home/rank",
    component: _b7d6972c,
    name: "home-rank___zh"
  }, {
    path: "/integral/mall",
    component: _098c75b6,
    name: "integral-mall___zh"
  }, {
    path: "/investigate/view",
    component: _5f04d092,
    name: "investigate-view___zh"
  }, {
    path: "/watch/catePanel",
    component: _50f03ec3,
    name: "watch-catePanel___zh"
  }, {
    path: "/watch/course",
    component: _346c423d,
    name: "watch-course___zh"
  }, {
    path: "/watch/practice",
    component: _87978e46,
    name: "watch-practice___zh"
  }, {
    path: "/watch/task",
    component: _6e7ee5b2,
    name: "watch-task___zh"
  }, {
    path: "/account/exam/detail",
    component: _b2698126,
    name: "account-exam-detail___zh"
  }, {
    path: "/account/exam/examQuestion",
    component: _8b58d97e,
    name: "account-exam-examQuestion___zh"
  }, {
    path: "/account/exam/examSuccess",
    component: _789dacf8,
    name: "account-exam-examSuccess___zh"
  }, {
    path: "/account/exam/record",
    component: _105d936d,
    name: "account-exam-record___zh"
  }, {
    path: "/account/plan/detail",
    component: _16f5fbba,
    name: "account-plan-detail___zh"
  }, {
    path: "/development/estimate/batchEvaluation",
    component: _8f6f7736,
    name: "development-estimate-batchEvaluation___zh"
  }, {
    path: "/development/estimate/evaluation",
    component: _b648da86,
    name: "development-estimate-evaluation___zh"
  }, {
    path: "/development/estimate/report",
    component: _4c7e0135,
    name: "development-estimate-report___zh"
  }, {
    path: "/en/development/estimate",
    component: _6333dde1,
    name: "development-estimate___en"
  }, {
    path: "/en/development/qualification",
    component: _2e06d528,
    name: "development-qualification___en"
  }, {
    path: "/en/development/study",
    component: _5c94bec6,
    name: "development-study___en"
  }, {
    path: "/en/home/rank",
    component: _b7d6972c,
    name: "home-rank___en"
  }, {
    path: "/en/integral/mall",
    component: _098c75b6,
    name: "integral-mall___en"
  }, {
    path: "/en/investigate/view",
    component: _5f04d092,
    name: "investigate-view___en"
  }, {
    path: "/en/watch/catePanel",
    component: _50f03ec3,
    name: "watch-catePanel___en"
  }, {
    path: "/en/watch/course",
    component: _346c423d,
    name: "watch-course___en"
  }, {
    path: "/en/watch/practice",
    component: _87978e46,
    name: "watch-practice___en"
  }, {
    path: "/en/watch/task",
    component: _6e7ee5b2,
    name: "watch-task___en"
  }, {
    path: "/account/plan/task/add",
    component: _7156d169,
    name: "account-plan-task-add___zh"
  }, {
    path: "/account/plan/task/detail",
    component: _6f30c48e,
    name: "account-plan-task-detail___zh"
  }, {
    path: "/en/account/exam/detail",
    component: _b2698126,
    name: "account-exam-detail___en"
  }, {
    path: "/en/account/exam/examQuestion",
    component: _8b58d97e,
    name: "account-exam-examQuestion___en"
  }, {
    path: "/en/account/exam/examSuccess",
    component: _789dacf8,
    name: "account-exam-examSuccess___en"
  }, {
    path: "/en/account/exam/record",
    component: _105d936d,
    name: "account-exam-record___en"
  }, {
    path: "/en/account/plan/detail",
    component: _16f5fbba,
    name: "account-plan-detail___en"
  }, {
    path: "/en/development/estimate/batchEvaluation",
    component: _8f6f7736,
    name: "development-estimate-batchEvaluation___en"
  }, {
    path: "/en/development/estimate/evaluation",
    component: _b648da86,
    name: "development-estimate-evaluation___en"
  }, {
    path: "/en/development/estimate/report",
    component: _4c7e0135,
    name: "development-estimate-report___en"
  }, {
    path: "/knowledge/offcourse/components/AssessAttachment",
    component: _339f5818,
    name: "knowledge-offcourse-components-AssessAttachment___zh"
  }, {
    path: "/en/account/plan/task/add",
    component: _7156d169,
    name: "account-plan-task-add___en"
  }, {
    path: "/en/account/plan/task/detail",
    component: _6f30c48e,
    name: "account-plan-task-detail___en"
  }, {
    path: "/en/knowledge/offcourse/components/AssessAttachment",
    component: _339f5818,
    name: "knowledge-offcourse-components-AssessAttachment___en"
  }, {
    path: "/",
    component: _d5e5fc40,
    name: "index___zh"
  }, {
    path: "/en/account/exam/:id?",
    component: _2be570ce,
    name: "account-exam-id___en"
  }, {
    path: "/en/account/task/:id?",
    component: _43632834,
    name: "account-task-id___en"
  }, {
    path: "/en/integral/goods/:id?",
    component: _22e4c2c0,
    name: "integral-goods-id___en"
  }, {
    path: "/en/investigate/analyze/:id?",
    component: _76e5d956,
    name: "investigate-analyze-id___en"
  }, {
    path: "/en/knowledge/course/:id?",
    component: _793e5cd9,
    name: "knowledge-course-id___en"
  }, {
    path: "/en/knowledge/offcourse/:id?",
    component: _c1f8819c,
    name: "knowledge-offcourse-id___en"
  }, {
    path: "/en/knowledge/project/:id?",
    component: _7932b241,
    name: "knowledge-project-id___en"
  }, {
    path: "/en/lecturer/view/:id?",
    component: _8ba6a84e,
    name: "lecturer-view-id___en"
  }, {
    path: "/en/live/watch/:watch?",
    component: _1490c63e,
    name: "live-watch-watch___en"
  }, {
    path: "/account/exam/:id?",
    component: _2be570ce,
    name: "account-exam-id___zh"
  }, {
    path: "/account/task/:id?",
    component: _43632834,
    name: "account-task-id___zh"
  }, {
    path: "/en/redirect/:path?",
    component: _3db3271a,
    name: "redirect-path___en"
  }, {
    path: "/integral/goods/:id?",
    component: _22e4c2c0,
    name: "integral-goods-id___zh"
  }, {
    path: "/investigate/analyze/:id?",
    component: _76e5d956,
    name: "investigate-analyze-id___zh"
  }, {
    path: "/knowledge/course/:id?",
    component: _793e5cd9,
    name: "knowledge-course-id___zh"
  }, {
    path: "/knowledge/offcourse/:id?",
    component: _c1f8819c,
    name: "knowledge-offcourse-id___zh"
  }, {
    path: "/knowledge/project/:id?",
    component: _7932b241,
    name: "knowledge-project-id___zh"
  }, {
    path: "/lecturer/view/:id?",
    component: _8ba6a84e,
    name: "lecturer-view-id___zh"
  }, {
    path: "/live/watch/:watch?",
    component: _1490c63e,
    name: "live-watch-watch___zh"
  }, {
    path: "/redirect/:path?",
    component: _3db3271a,
    name: "redirect-path___zh"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
